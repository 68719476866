import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Music, User, Users, Upload, LogOut, BarChart, FileMusic, PlusCircle, ShoppingCart, Zap, DollarSign, Play, Pause, X, Globe, Award } from 'lucide-react';
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line } from 'recharts';

const generateData = (baseValue, months, increment) => {
  return [...Array(months)].map((_, i) => ({
    month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][i],
    value: Math.floor(baseValue + (Math.random() * baseValue * 0.1) + (i * increment))
  }));
};

const StatCard = ({ icon: Icon, value, label }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="bg-white p-6 rounded-lg shadow-lg text-center"
  >
    <Icon className="w-12 h-12 mx-auto mb-4 text-purple-500" />
    <h3 className="text-3xl font-bold text-gray-800 mb-2">{value}</h3>
    <p className="text-gray-600">{label}</p>
  </motion.div>
);

const LiveListenerCounter = () => {
  const [listeners, setListeners] = useState(5000);

  useEffect(() => {
    const interval = setInterval(() => {
      setListeners(prev => {
        const change = Math.floor(Math.random() * 100) - 50; // -50 to 50
        return Math.max(5000, Math.min(15000, prev + change));
      });
    }, 30000); // Update every 30 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
      <h3 className="text-xl font-semibold mb-2">Live Listeners</h3>
      <p className="text-3xl font-bold text-purple-600">{listeners.toLocaleString()}</p>
    </div>
  );
};

const AnimatedBackground = () => (
  <div className="fixed inset-0 z-0 pointer-events-none overflow-hidden">
    <svg className="absolute w-full h-full" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#8B5CF6" stopOpacity="0.1" />
          <stop offset="100%" stopColor="#EC4899" stopOpacity="0.1" />
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill="url(#grad1)" />
      {[...Array(20)].map((_, i) => (
        <motion.circle
          key={i}
          r={Math.random() * 20 + 5}
          fill="#fff"
          opacity={0.1}
          initial={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
          }}
          animate={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
          }}
          transition={{
            duration: Math.random() * 10 + 10,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </svg>
  </div>
);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [demoFile, setDemoFile] = useState(null);
  const [demoEmail, setDemoEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const [showDiscount, setShowDiscount] = useState(false);
  const [activeDashboardTab, setActiveDashboardTab] = useState('analysis');
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [purchaseEmail, setPurchaseEmail] = useState('');
  const [isPremium, setIsPremium] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [blurDashboard, setBlurDashboard] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showInitialPurchasePrompt, setShowInitialPurchasePrompt] = useState(false);
  const [monthlyListeners, setMonthlyListeners] = useState(generateData(500000, 12, 50000));
  const [totalPlays, setTotalPlays] = useState(generateData(2000000, 12, 100000));
  const [waitlistEmail, setWaitlistEmail] = useState('');

  const plans = {
    yearly: { originalPrice: '$199', discountedPrice: '$179', period: 'year' },
    monthly: { originalPrice: '$19.99', discountedPrice: '$17.99', period: 'month' }
  };

  // Simulated user data
  const userData = {
    totalPlays: 1234567,
    monthlyListeners: 234567,
    totalEarnings: 5678.90,
    recentTracks: [
      { id: 1, title: "Demo Track 1", plays: 50000, earnings: 500 },
      { id: 2, title: "Demo Track 2", plays: 75000, earnings: 750 },
      { id: 3, title: "Demo Track 3", plays: 100000, earnings: 1000 },
    ]
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setMonthlyListeners(generateData(500000, 12, 50000));
      setTotalPlays(generateData(2000000, 12, 100000));
    }, 300000); // Update every 5 minutes

    return () => clearInterval(interval);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Simulated login
    setTimeout(() => {
      setIsLoggedIn(true);
      setUser({ email: email });
      setMessage('Login successful!');
      setActiveSection('dashboard');
      setIsLoading(false);
    }, 1000);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUser(null);
    setIsPremium(false);
    setMessage('Logged out successfully.');
    setActiveSection('home');
  };

  const handleDemoSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Simulated demo submission
    setTimeout(() => {
      setMessage('Demo submitted successfully! We will contact you soon.');
      setDemoFile(null);
      setDemoEmail('');
      setIsLoading(false);
    }, 2000);
  };

  const handleStartNow = () => {
    setShowDiscount(true);
    setTimeout(() => {
      setShowDiscount(false);
      setActiveSection('register');
    }, 3000);
  };

  const handlePurchase = () => {
    setShowPurchaseModal(true);
    setBlurDashboard(true);
  };

  const closePurchaseModal = () => {
    setShowPurchaseModal(false);
    setBlurDashboard(false);
    setShowInitialPurchasePrompt(false);
  };

  const completePurchase = () => {
    setIsPurchasing(true);
    setTimeout(() => {
      setIsPurchasing(false);
      setMessage('Unfortunately, all our user slots are full. Please leave your email so we can contact you when a slot opens up.');
      setShowPurchaseModal(false);
      setBlurDashboard(true);
    }, 3000);
  };

  const handlePurchaseEmailSubmit = (e) => {
    e.preventDefault();
    setMessage(`Thank you! We'll notify you at ${purchaseEmail} when a new slot opens up.`);
    setPurchaseEmail('');
    handleLogout();
  };

  const handleTrackPlay = (track) => {
    setCurrentTrack(track);
    setIsPlaying(true);
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleWaitlistSubmit = (e) => {
    e.preventDefault();
    setMessage('Your application has been successfully received. We will get back to you.');
    setWaitlistEmail('');
  };

  const renderDashboardContent = () => {
    if (!isPremium && (showInitialPurchasePrompt || activeDashboardTab === 'analysis')) {
      return (
        <div className="p-4 text-center">
          <h2 className="text-2xl font-bold mb-4">Premium Features</h2>
          <p className="mb-4">Premium membership is required to access this feature.</p>
          <button
            onClick={handlePurchase}
            className="bg-purple-600 text-white px-6 py-2 rounded-full hover:bg-purple-700 transition duration-300"
          >
            Upgrade to Premium
          </button>
        </div>
      );
    }

    switch (activeDashboardTab) {
      case 'analysis':
        return (
          <div className="p-4 space-y-6">
            <h2 className="text-2xl font-bold mb-4">Analysis</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-lg font-semibold mb-2">Total Plays</h3>
                <p className="text-3xl font-bold text-purple-600">{userData.totalPlays.toLocaleString()}</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-lg font-semibold mb-2">Monthly Listeners</h3>
                <p className="text-3xl font-bold text-purple-600">{userData.monthlyListeners.toLocaleString()}</p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-lg font-semibold mb-2">Total Earnings</h3>
                <p className="text-3xl font-bold text-purple-600">${userData.totalEarnings.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
              </div>
            </div>
            <LiveListenerCounter />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4">Monthly Listeners</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={monthlyListeners}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke="#8B5CF6" strokeWidth={2} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-4">Total Plays</h3>
                <ResponsiveContainer width="100%" height={300}>
                <RechartsBarChart data={totalPlays}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#EC4899" />
                  </RechartsBarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow mt-8">
              <h3 className="text-lg font-semibold mb-4">Recent Tracks</h3>
              <table className="w-full">
                <thead>
                  <tr className="text-left">
                    <th>Track</th>
                    <th>Plays</th>
                    <th>Earnings</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userData.recentTracks.map(track => (
                    <tr key={track.id} className="border-t">
                      <td className="py-2">{track.title}</td>
                      <td>{track.plays.toLocaleString()}</td>
                      <td>${track.earnings.toLocaleString()}</td>
                      <td>
                        <button onClick={() => handleTrackPlay(track)} className="text-purple-600 hover:text-purple-800">
                          <Play size={18} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      case 'songs':
        return (
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">My Songs</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {userData.recentTracks.map(track => (
                <div key={track.id} className="bg-white p-4 rounded-lg shadow">
                  <h3 className="text-lg font-semibold mb-2">{track.title}</h3>
                  <p>Plays: {track.plays.toLocaleString()}</p>
                  <p>Earnings: ${track.earnings.toLocaleString()}</p>
                  <button onClick={() => handleTrackPlay(track)} className="mt-2 text-purple-600 hover:text-purple-800">
                    {currentTrack?.id === track.id && isPlaying ? <Pause size={18} /> : <Play size={18} />}
                    {currentTrack?.id === track.id && isPlaying ? 'Pause' : 'Play'}
                  </button>
                </div>))}
            </div>
          </div>
        );
      case 'create':
        return (
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Create New Song</h2>
            {isPremium ? (
              <form className="space-y-4">
                <input type="text" placeholder="Song Title" className="w-full p-2 border rounded" />
                <input type="file" accept=".mp3,.wav" className="w-full p-2 border rounded" />
                <button type="submit" className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700">Upload</button>
              </form>
            ) : (
              <div>
                <p className="mb-4">Premium membership is required to upload new songs.</p>
                <button
                  onClick={handlePurchase}
                  className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
                >
                  Upgrade to Premium
                </button>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'home':
        return (
          <motion.div className="space-y-8">
            <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
              Share Your Music with the World
            </h2>
            <p className="text-xl mb-8">
              Discover next-generation music distribution with Echo Digital Music Label
            </p>
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16"
            >
              <StatCard icon={Music} value="1M+" label="Monthly Plays" />
              <StatCard icon={Users} value="500K+" label="Active Listeners" />
              <StatCard icon={Globe} value="100+" label="Countries" />
              <StatCard icon={Award} value="500+" label="Successful Artists" />
            </motion.div>
            <LiveListenerCounter />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              className="bg-white p-8 rounded-lg shadow-xl mb-16"
            >
              <h2 className="text-3xl font-bold mb-8 text-gray-800 text-center">Listener Statistics</h2>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div>
                  <h3 className="text-xl font-semibold mb-4 text-gray-700">Monthly Listeners</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={monthlyListeners}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Line type="monotone" dataKey="value" stroke="#8B5CF6" strokeWidth={2} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-4 text-gray-700">Total Plays</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <RechartsBarChart data={totalPlays}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#EC4899" />
                    </RechartsBarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </motion.div>
            <div className="sticky bottom-20 bg-white p-4 rounded-lg shadow-lg z-40">
              <motion.button
                className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white px-8 py-3 rounded-full font-semibold text-lg shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setActiveSection('pricing')}
              >
                See Pricing
              </motion.button>
            </div>
          </motion.div>
        );
      case 'login':
        return (
          <motion.div className="max-w-md mx-auto">
            <h3 className="text-3xl font-bold text-center mb-8">Log In</h3>
            <form onSubmit={handleLogin} className="space-y-4">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 border rounded-lg"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 border rounded-lg"
              />
              <motion.button
                type="submit"
                className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white p-3 rounded-lg font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Log In'}
              </motion.button>
            </form>
          </motion.div>
        );
      case 'register':
        return (
          <motion.div className="max-w-md mx-auto">
            <h3 className="text-3xl font-bold text-center mb-8">Join Our Waitlist</h3>
            <form onSubmit={handleWaitlistSubmit} className="space-y-4">
              <input
                type="email"
                placeholder="Email"
                value={waitlistEmail}
                onChange={(e) => setWaitlistEmail(e.target.value)}
                className="w-full p-3 border rounded-lg"
                required
              />
              <motion.button
                type="submit"
                className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white p-3 rounded-lg font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Join Waitlist
              </motion.button>
            </form>
          </motion.div>
        );
      case 'pricing':
        return (
          <motion.div className="space-y-8">
            <h2 className="text-3xl font-bold mb-6">Pricing</h2>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="bg-gradient-to-r from-purple-500 to-pink-500 text-white p-6">
                <h3 className="text-2xl font-bold mb-2">Annual Plan</h3>
                <p className="text-4xl font-bold">
                  <span className="line-through">{plans.yearly.originalPrice}</span>{' '}
                  {plans.yearly.discountedPrice}<span className="text-sm">/{plans.yearly.period}</span>
                </p>
              </div>
              <div className="p-6">
                <ul className="space-y-4">
                  {[
                    "Unlimited music uploads",
                    "Distribution to 150+ stores",
                    "100% revenue share",
                    "Detailed sales reports",
                    "24/7 support"
                  ].map((feature, index) => (
                    <motion.li
                      key={index}
                      className="flex items-center"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      {feature}
                    </motion.li>
                  ))}
                </ul>
                <motion.button
                  className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white mt-8 py-3 rounded-full text-xl font-bold"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleStartNow}
                >
                  Start Now
                </motion.button>
              </div>
            </div>
          </motion.div>
        );
      case 'demo':
        return (
          <motion.div className="space-y-8">
            <h2 className="text-3xl font-bold mb-6">
              Send Us Your Demo
            </h2>
            <form onSubmit={handleDemoSubmit} className="space-y-4">
              <input
                type="email"
                placeholder="Email"
                value={demoEmail}
                onChange={(e) => setDemoEmail(e.target.value)}
                className="w-full p-3 border rounded-lg"
              />
              <input
                type="file"
                onChange={(e) => setDemoFile(e.target.files[0])}
                className="w-full p-3 border rounded-lg"
              />
              <motion.button
                type="submit"
                className="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white p-3 rounded-lg font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={isLoading}
              >
                {isLoading ? 'Sending...' : 'Send Demo'}
              </motion.button>
            </form>
          </motion.div>
        );
      case 'dashboard':
        return (
          <motion.div className={`space-y-8 ${blurDashboard ? 'filter blur-sm' : ''}`}>
            <h2 className="text-3xl font-bold mb-6">Welcome, {user?.email}</h2>
            <div className="flex space-x-4 mb-4">
              <button
                className={`px-4 py-2 rounded ${activeDashboardTab === 'analysis' ? 'bg-purple-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveDashboardTab('analysis')}
              >
                <BarChart className="inline-block mr-2" />
                Analysis
              </button>
              <button
                className={`px-4 py-2 rounded ${activeDashboardTab === 'songs' ? 'bg-purple-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveDashboardTab('songs')}
              >
                <FileMusic className="inline-block mr-2" />
                My Songs
              </button>
              <button
                className={`px-4 py-2 rounded ${activeDashboardTab === 'create' ? 'bg-purple-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveDashboardTab('create')}
              >
                <PlusCircle className="inline-block mr-2" />
                Create New Song
              </button>
            </div>
            {renderDashboardContent()}
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-100 text-gray-900 min-h-screen font-sans relative">
      <AnimatedBackground />
      {/* Header */}
      <header className="bg-white shadow-md sticky top-0 z-50">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <motion.h1 
            className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Echo Digital Music Label
          </motion.h1>
          <nav className="flex items-center">
            <AnimatePresence>
              {isLoggedIn ? (
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  className="flex items-center space-x-4"
                >
                  <span className="text-gray-600">Hello, {user?.email}</span>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleLogout}
                    className="flex items-center space-x-2 bg-red-500 text-white px-4 py-2 rounded-full"
                  >
                    <LogOut size={18} />
                    <span>Log Out</span>
                  </motion.button>
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  className="flex space-x-4"
                >
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setActiveSection('login')}
                    className="bg-purple-500 text-white px-4 py-2 rounded-full"
                  >
                    Log In
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setActiveSection('register')}
                    className="bg-pink-500 text-white px-4 py-2 rounded-full"
                  >
                    Join Waitlist
                  </motion.button>
                </motion.div>
              )}
            </AnimatePresence>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8 relative z-10 pb-24">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            {renderContent()}
          </motion.div>
        </AnimatePresence>
      </main>

      {/* Navigation */}
      <nav className="fixed bottom-0 left-0 right-0 bg-white shadow-lg z-50">
        <div className="container mx-auto px-4 py-2">
          <ul className="flex justify-around">
            {[
              { id: 'home', icon: Music, label: 'Home' },
              { id: 'pricing', icon: ShoppingCart, label: 'Pricing' },
              { id: 'demo', icon: Upload, label: 'Send Demo' },
              ...(isLoggedIn ? [{ id: 'dashboard', icon: User, label: 'Dashboard' }] : []),
            ].map((item) => (
              <motion.li key={item.id} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <button
                  onClick={() => setActiveSection(item.id)}
                  className={`flex flex-col items-center p-2 ${
                    activeSection === item.id ? 'text-purple-500' : 'text-gray-500'
                  }`}
                >
                  <item.icon size={24} />
                  <span className="text-xs mt-1">{item.label}</span>
                </button>
              </motion.li>
            ))}
          </ul>
        </div>
      </nav>

      {/* Message Toast */}
      <AnimatePresence>
        {message && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
            className="fixed bottom-20 left-0 right-0 mx-auto w-full max-w-sm bg-green-500 text-white p-4 rounded-lg shadow-lg text-center z-50"
          >
            {message}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Discount Animation */}
      <AnimatePresence>
        {showDiscount && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.5 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <div className="bg-white p-8 rounded-lg shadow-xl text-center">
              <h2 className="text-3xl font-bold mb-4">Congratulations!</h2>
              <p className="text-xl mb-4">You've earned a 10% discount!</p>
              <motion.div
                className="text-5xl font-bold text-purple-600"
                initial={{ rotateY: 0 }}
                animate={{ rotateY: 360 }}
                transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }}
              >
                10%
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Purchase Modal */}
      <AnimatePresence>
        {(showPurchaseModal || showInitialPurchasePrompt) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full"
            >
              <h2 className="text-2xl font-bold mb-4">Upgrade to Premium</h2>
              <p className="mb-4">Upgrade to Premium to access all features!</p>
              <p className="text-3xl font-bold mb-4">$199<span className="text-sm">/year</span></p>
              <button
                onClick={completePurchase}
                className="w-full bg-purple-600 text-white p-3 rounded-lg font-semibold hover:bg-purple-700"
              >
                Purchase Now
              </button>
              <button
                onClick={closePurchaseModal}
                className="w-full mt-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Loading Animation */}
      <AnimatePresence>
        {isPurchasing && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <div className="bg-white p-8 rounded-lg shadow-xl text-center">
              <h2 className="text-2xl font-bold mb-4">Processing Your Request</h2>
              <p className="mb-4">Please wait...</p>
              <div className="w-16 h-16 border-t-4 border-purple-500 border-solid rounded-full animate-spin mx-auto"></div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Email Form Modal */}
      <AnimatePresence>
        {!isPurchasing && message.includes('leave your email') && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
              <h2 className="text-2xl font-bold mb-4">Waitlist</h2>
              <p className="mb-4">{message}</p>
              <form onSubmit={handlePurchaseEmailSubmit} className="space-y-4">
                <input
                  type="email"
                  placeholder="Your email address"
                  value={purchaseEmail}
                  onChange={(e) => setPurchaseEmail(e.target.value)}
                  className="w-full p-2 border rounded"
                  required
                />
                <button
                  type="submit"
                  className="w-full bg-purple-500 text-white p-2 rounded hover:bg-purple-600"
                >
                  Submit
                </button>
              </form>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default App;